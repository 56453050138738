import React from 'react';
import ReactDOM from 'react-dom/client';
import ProgressCircle from "./components/ProgressCircle";
import Footer from "./components/Footer";
import Header from "./components/Header";
import {ApiProvider} from "./contexts/Api";
import ContentRoot from "./content/ContentRoot";
import {BrowserRouter} from "react-router-dom";
import {UserProvider} from "./contexts/User";
import WidgetsOverlay from "./components/WidgetsOverlay";
import StorageProvider from "./contexts/Storage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <UserProvider>
                <StorageProvider>
                    <ApiProvider>
                        <div className="content-wrapper">
                            <Header/>
                            <ContentRoot/>
                        </div>
                        <Footer/>
                        <ProgressCircle/>
                        <WidgetsOverlay/>
                    </ApiProvider>
                </StorageProvider>
            </UserProvider>
        </BrowserRouter>
    </React.StrictMode>
);
