import {useContext, useEffect, useState} from "react";
import {ApiContext} from "../contexts/Api";
import ProductsListItem from "../components/ProductsListItem";
import Pager from "../components/Pager";
import {useLocation} from "react-router-dom";
import styles from '../custom.module.css';

let oldSearch = '';

export default function SearchResults(props) {
    const {Api} = useContext(ApiContext);
    const location = useLocation();

    const [initialSearch, setInitialSearch] = useState(true);

    useEffect(() => {
        if (Api.searchTerm === '') {
            const url = new URL(window.location.origin + location.pathname + location.search)
            if (url.searchParams.has('q')) {
                Api.updateSearchTerm(url.searchParams.get('q'));
                Api.doSearch({
                    term: url.searchParams.get('q'),
                    order: url.searchParams.has('o') ? parseInt(url.searchParams.get('o')) : 1,
                    page: url.searchParams.has('p') ? parseInt(url.searchParams.get('p')) : 1,
                });
            }
        } else {
            setInitialSearch(false);
        }
    }, []);

    useEffect(() => {
        if (oldSearch !== location.search) {
            console.log('MEKLĒ', Api.searchInProgress);
            if (!Api.searchInProgress) {
                const url = new URL(window.location.origin + location.pathname + location.search)
                if (url.searchParams.has('q')) {
                    Api.updateSearchTerm(url.searchParams.get('q'));
                    Api.doSearch({
                        term: url.searchParams.get('q'),
                        order: url.searchParams.has('o') ? parseInt(url.searchParams.get('o')) : 1,
                        page: url.searchParams.has('p') ? parseInt(url.searchParams.get('p')) : 1,
                    });
                }
            }
            oldSearch = location.search;
        }
    }, [location]);


    return (Api.searchResults.length && (!Api.searchInProgress || !location.state.showSearchingNotification) ? <section className={styles.wrapperLight}>
        <div className="container py-8 py-md-10">
            <div className="row align-items-center mb-10 position-relative zindex-1">
                <div className="col-md-8 col-lg-9 col-xl-8 col-xxl-7 pe-xl-20">
                    <p className="m-0">
                        {
                            (Api.totalProducts == 0 || Api.totalProducts % 100 == 11) ?
                                `Atrasti ${Api.totalProducts} rezultāti` :
                                (Api.totalProducts % 10 == 1 ? `Atrasts ${Api.totalProducts} rezultāts` : `Atrasti ${Api.totalProducts} rezultāti`)
                        }
                    </p>
                </div>

                <div className="col-md-4 col-lg-3 ms-md-auto text-md-end mt-5 mt-md-0">
                    <div className="form-select-wrapper">
                        <select className="form-select" onChange={e => Api.updateSearchOrder(parseInt(e.target.value))} disabled={false} value={String(Api.searchOrder)}>
                            <option value="1">Lētākais augšgalā</option>
                            <option value="2">Zemākā cena par kg/l/gab pirmā</option>
                        </select>
                    </div>

                </div>

            </div>

            <div className="grid grid-view mb-13">
                <div className="row gx-md-8 gy-10 gy-md-13 isotope">
                    {Api.searchResults.map(item => <ProductsListItem key={item.uuid} data={item}/>)}
                </div>

            </div>

            <Pager totalPages={Api.totalPages} page={Api.currentPage} onChangePage={Api.changePage}/>

        </div>

    </section> : (!Api.searchInProgress && !initialSearch ?
        <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center'}}>
            <svg xmlns="http://www.w3.org/2000/svg" id="svg2" viewBox="0 0 461.96 450.75" version="1.1" style={{
                width: '400px',
                maxWidth: '80%',
                marginTop: '1.5rem'
            }}>
                <defs id="defs4">
                    <filter id="filter4456" color-interpolation-filters="sRGB">
                        <feGaussianBlur id="feGaussianBlur4458" in="SourceGraphic" stdDeviation="6" result="result0"/>
                        <feDiffuseLighting id="feDiffuseLighting4460" lighting-color="#ffffff" result="result5" diffuseConstant="1" surfaceScale="4">
                            <feDistantLight id="feDistantLight4462" elevation="45" azimuth="235"/>
                        </feDiffuseLighting>
                        <feComposite id="feComposite4464" in="result5" in2="SourceGraphic" k3="0" k2="0" k1="1.4" result="fbSourceGraphic" k4="0" operator="arithmetic"/>
                        <feGaussianBlur id="feGaussianBlur4466" result="result0" stdDeviation="6" in="fbSourceGraphic"/>
                        <feSpecularLighting id="feSpecularLighting4468" lighting-color="#ffffff" specularConstant="1" surfaceScale="4" result="result1" specularExponent="25" in="result0">
                            <feDistantLight id="feDistantLight4470" elevation="45" azimuth="235"/>
                        </feSpecularLighting>
                        <feComposite id="feComposite4472" in="fbSourceGraphic" in2="result1" k3="1" k2="1" k1="0" result="result4" k4="0" operator="arithmetic"/>
                        <feComposite id="feComposite4474" operator="in" result="result2" in2="SourceGraphic" in="result4"/>
                    </filter>
                </defs>
                <g id="layer5" stroke-linejoin="round" transform="translate(-78.068 10.129)" stroke-linecap="round" stroke-width="24" fill="none">
                    <path id="path4430" stroke="#806600" d="m128.92 270.44s-16.312-241.96 181.08-241.96 179.08 241.96 179.08 241.96"/>
                    <path id="path4454" opacity=".5" filter="url(#filter4456)" stroke="#000" d="m128.92 270.44s-16.312-241.96 181.08-241.96 179.08 241.96 179.08 241.96"/>
                </g>
                <g id="layer2" transform="translate(-78.068 10.129)">
                    <path id="rect4031" fill="#504416" d="m113.14 234.6 388.72 0.5601-57.131 194.92-269.97 0.56011z"/>
                </g>
                <g id="layer3" stroke-linejoin="round" transform="translate(-78.068 10.129)" stroke="#806600" stroke-linecap="round" stroke-width="16" fill="none">
                    <path id="path2988" d="m310.27 221.68-0.56011 203.66"/>
                    <path id="path3760" d="m110.83 222.08 57.199 203.98"/>
                    <path id="path3762" d="m212.51 221.44 27.199 205.1"/>
                    <path id="path3766" d="m507.47 218.72-56.08 206.78"/>
                    <path id="path3768" d="m409.15 220.32-28.87 207.16"/>
                </g>
                <g id="layer4" stroke-linejoin="round" stroke-linecap="round" fill="none" transform="translate(-78.068 10.129)">
                    <g id="g3123" stroke="#d4aa00" stroke-width="11.877" transform="matrix(.70895 0 0 1 90.142 174)">
                        <path id="path3125" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3127" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3129" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3131" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g4019" stroke="#a80" stroke-width="13.379" transform="matrix(.74688 0 0 .74795 78.269 239.94)">
                        <path id="path4021" d="m255.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path4023" d="m355.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path4025" d="m171.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path4027" d="m107.77 238.68s23.845 10 36.441 10 36.441-10 36.441-10"/>
                        <path id="path4029" d="m510.65 238.68s-23.845 10-36.441 10-36.441-10-36.441-10"/>
                    </g>
                    <g id="g3113" stroke="#d4aa00" stroke-width="11.58" transform="matrix(.74579 0 0 1 78.732 158)">
                        <path id="path3115" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3117" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3119" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3121" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g4007" stroke="#a80" stroke-width="11.421" transform="matrix(.76659 0 0 1 72.174 160)">
                        <path id="path4009" d="m255.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path4011" d="m355.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path4013" d="m171.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path4015" d="m107.77 238.68s23.845 10 36.441 10 36.441-10 36.441-10"/>
                        <path id="path4017" d="m510.65 238.68s-23.845 10-36.441 10-36.441-10-36.441-10"/>
                    </g>
                    <g id="g3103" stroke="#d4aa00" stroke-width="11.419" transform="matrix(.76690 0 0 1 72.194 138)">
                        <path id="path3105" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3107" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3109" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3111" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g3995" stroke="#a80" stroke-width="11.095" transform="matrix(.81238 0 0 1 58.015 136)">
                        <path id="path3997" d="m255.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3999" d="m355.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path4001" d="m171.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path4003" d="m107.77 238.68s23.845 10 36.441 10 36.441-10 36.441-10"/>
                        <path id="path4005" d="m510.65 238.68s-23.845 10-36.441 10-36.441-10-36.441-10"/>
                    </g>
                    <g id="g3058" stroke="#d4aa00" stroke-width="11.145" transform="matrix(.80508 0 0 1 60.37 112)">
                        <path id="path3060" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3062" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3064" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3066" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g3983" stroke="#a80" stroke-width="10.892" transform="matrix(.84293 0 0 1 48.569 110)">
                        <path id="path3985" d="m255.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3987" d="m355.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3989" d="m171.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3991" d="m107.77 238.68s23.845 10 36.441 10 36.441-10 36.441-10"/>
                        <path id="path3993" d="m510.65 238.68s-23.845 10-36.441 10-36.441-10-36.441-10"/>
                    </g>
                    <g id="g3048" stroke="#d4aa00" stroke-width="10.905" transform="matrix(.84084 0 0 1 49.294 86)">
                        <path id="path3050" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3052" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3054" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3056" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g3196" stroke="#a80" stroke-width="10.7" transform="matrix(.87352 0 0 1 39.11 84)">
                        <path id="path3198" d="m255.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3200" d="m355.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3202" d="m171.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3204" d="m107.77 238.68s23.845 10 36.441 10 36.441-10 36.441-10"/>
                        <path id="path3206" d="m510.65 238.68s-23.845 10-36.441 10-36.441-10-36.441-10"/>
                    </g>
                    <g id="g3038" stroke="#d4aa00" stroke-width="10.657" transform="matrix(.88056 0 0 1 36.993 60)">
                        <path id="path3040" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3042" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3044" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3046" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g3184" stroke="#a80" stroke-width="10.495" transform="matrix(.90797 0 0 1 28.458 56)">
                        <path id="path3186" d="m255.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3188" d="m355.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3190" d="m171.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3192" d="m107.77 238.68s23.845 10 36.441 10 36.441-10 36.441-10"/>
                        <path id="path3194" d="m510.65 238.68s-23.845 10-36.441 10-36.441-10-36.441-10"/>
                    </g>
                    <g id="g3028" stroke="#d4aa00" stroke-width="10.469" transform="matrix(.91240 0 0 1 27.131 32)">
                        <path id="path3030" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3032" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3034" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3036" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g3172" stroke="#a80" stroke-width="10.218" transform="matrix(.95779 0 0 1 13.051 28)">
                        <path id="path3174" d="m255.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3176" d="m355.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3178" d="m171.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3180" d="m107.77 238.68s23.845 10 36.441 10 36.441-10 36.441-10"/>
                        <path id="path3182" d="m510.65 238.68s-23.845 10-36.441 10-36.441-10-36.441-10"/>
                    </g>
                    <g id="g3022" stroke="#d4aa00" stroke-width="10.248" transform="matrix(.95222 0 0 1 14.799 4)">
                        <path id="path3774" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3016" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3018" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3020" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g3165" stroke="#a80" stroke-width="10">
                        <path id="path3145" d="m255.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3157" d="m355.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3159" d="m171.34 238.68s36.151 10 54.659 10 54.659-10 54.659-10"/>
                        <path id="path3161" d="m107.77 238.68s23.845 10 36.441 10 36.441-10 36.441-10"/>
                        <path id="path3163" d="m510.65 238.68s-23.845 10-36.441 10-36.441-10-36.441-10"/>
                    </g>
                    <g id="g4476" stroke="#a80" stroke-width="12.422" transform="matrix(.95212 0 0 -.68063 14.828 400.67)">
                        <path id="path4478" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path4480" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path4482" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path4484" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                    <g id="g3068" stroke="#d4aa00" stroke-width="10.122" transform="matrix(.97606 0 0 1 7.4155 -24)">
                        <path id="path3070" d="m203.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3072" d="m303.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3074" d="m403.71 250.68s37.038 10 56 10 56-10 56-10"/>
                        <path id="path3076" d="m103.71 250.68s37.038 10 56 10 56-10 56-10"/>
                    </g>
                </g>
            </svg>
            <h2>Neatradu to, ko meklēji! :(</h2>

        </div> : (Api.searchInProgress ?
            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center'}}><h2>Notiek
                meklēšana...</h2></div> : '')));
}
