import {Link} from "react-router-dom";
import {useState} from "react";

export default function Footer() {
    const [subscribeEmailValue, setSubscribeEmailValue] = useState('');

    const handleSubscribeSubmit = event => {
        event.preventDefault();
        setSubscribeEmailValue('');
    };

    return (<footer className="bg-dark text-inverse">
        <div className="container py-13 py-md-15">
            <div className="row gy-6 gy-lg-0">
                <div className="col-md-4 col-lg-3">
                    <div className="widget">
                        <a href="/">LOGO</a>
                        <p className="mb-4">© 2022 Cibiņš. <br className="d-none d-lg-block"/>Tiesības uz preču attēliem
                            pieder attiecīgajiem veikaliem.</p>
                        <nav className="nav social social-white">
                            <a href="https://x.com" target="_blank" rel="noreferrer">
                                <svg viewBox="0 0 24 24" aria-hidden="true" style={{fill: 'white', width: '1em'}}>
                                    <g>
                                        <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                                    </g>
                                </svg>
                            </a>
                            <a href="https://facebook.com" target="_blank" rel="noreferrer"><i className="uil uil-facebook-f"></i></a>
                        </nav>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3">
                    <div className="widget">
                        <h4 className="widget-title text-white mb-3">Gribi uzrakstīt?</h4>
                        <a href="mailto:info@cibins.lv">info@cibins.lv</a>
                    </div>
                </div>
                <div className="col-md-4 col-lg-3">
                    <div className="widget">
                        <h4 className="widget-title text-white mb-3">Uzzini vairak</h4>
                        <ul className="list-unstyled  mb-0">
                            <li><Link to="/par-mums/">Par mums</Link></li>
                            <li><Link to="/noteikumi/">Lietošanas noteikumi</Link></li>
                            <li><Link to="/privatuma-politika/">Privātuma politika</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-12 col-lg-3">
                    <div className="widget">
                        <h4 className="widget-title text-white mb-3">Jaunumi</h4>
                        <p className="mb-5">Pieraksties, lai pirmais uzzinātu par jaunumiem.</p>
                        <div className="newsletter-wrapper">
                            <div id="mc_embed_signup2">
                                <form action="#" method="post" id="mc-embedded-subscribe-form2" name="mc-embedded-subscribe-form" className="validate dark-fields" target="_blank" noValidate onSubmit={handleSubscribeSubmit}>
                                    <div id="mc_embed_signup_scroll2">
                                        <div className="mc-field-group input-group form-floating">
                                            <input type="email" value={subscribeEmailValue} onChange={e => setSubscribeEmailValue(e.target.value)} name="EMAIL" className="required email form-control" placeholder="E-pasta adrese" id="mce-EMAIL2"/>
                                            <label htmlFor="mce-EMAIL2">E-pasta adrese</label>
                                            <button type="submit" name="subscribe" id="mc-embedded-subscribe2" className="btn btn-primary">Aiziet!</button>
                                        </div>
                                        <div id="mce-responses2" className="clear">
                                            <div className="response" id="mce-error-response2" style={{display: 'none'}}></div>
                                            <div className="response" id="mce-success-response2" style={{display: 'none'}}></div>
                                        </div>
                                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                                            <input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabIndex="-1" value=""/>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>);
}
