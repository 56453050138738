import {Link, useLocation, useParams} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import {ApiContext} from "../contexts/Api";
import GLightbox from 'glightbox';
import {
    CategoryScale,
    Chart as ChartJS,
    Colors,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {lv} from 'date-fns/locale';
import styles from "../custom.module.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Colors,
);

export const Xoptions = {
    scales: {
        x: {
            adapters: {
                date: {
                    locale: lv,
                },
            },
            major: {
                enabled: true
            },
            type: 'time',
            time: {
                unit: 'day',
                tooltipFormat: 'd. MMMM',
                displayFormats: {
                    day: 'd. MMM',
                },
            },
        },
        y: {
            beginAtZero: true,
            ticks: {
                callback: function (value, index, ticks) {
                    return value.toLocaleString('lv', {
                        style: 'currency',
                        currency: 'EUR',
                        currencyDisplay: 'narrowSymbol',
                    });
                },
            },
        },
    },
    elements: {
        point: {
            borderWidth: 2,
            hoverBorderWidth: 4,
            radius: 3,
            hoverRadius: 3,
            hitRadius: 3,
        },
        line: {
            tension: 0,
            stepped: false,
            fill: true,
            borderWidth: 3,
            borderCapStyle: 'round',
            borderJoinStyle: 'round',
        }
    },
    plugins: {
        tooltip: {
            xAlign: 'center',
            yAlign: 'bottom',
            backgroundColor: 'rgba(255,255,255,0.8)',
            titleColor: '#020202',
            bodyColor: '#060606',
            borderColor: 'rgba(6,6,6,0.2)',
            borderWidth: 1,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        mode: 'nearest',
        intersect: false,
    },
};

export const getDatesInRange = (firstDate, lastDate) => {
    let dates = [];
    lastDate = new Date(lastDate);

    dates.push(new Date(firstDate));
    if (firstDate.getTime() !== lastDate.getTime()) dates.push(new Date(lastDate));

    return dates;
};

export const chopRangeIntoDays = (data) => {
    const choppedData = [];
    let _last = 0;
    data.forEach(([firstseen, lastseen, price, promo, loyalty]) => {
        const _fs = new Date(firstseen * 1000);
        const _ls = new Date(lastseen * 1000);
        if (_last) {
            if (parseInt(String((_last + 86400000) / 86400000).split('.')[0]) < parseInt(String(_fs.getTime() / 86400000).split('.')[0])) {
                getDatesInRange(new Date((_last + 86400000)), new Date(_fs.getTime() - 86400000)).forEach(date => {
                    choppedData.push({x: date, y: [null, null, null]});
                });
            }
        }
        const dates = getDatesInRange(_fs, _ls);
        dates.forEach(date => {
            choppedData.push({x: date, y: [price, promo, loyalty]});
        });
        _last = _ls.getTime();
    });
    return choppedData;
}

export const filterConsecutiveDuplicates = arr => {
    let lastY = null;
    return arr.filter((c, index) => {
        // If the current y value is different from the last one, keep this item and update lastY
        if (lastY !== c.y) {
            lastY = c.y;
            return true;
        }
        // If this is the last item and the y value is a duplicate, still keep it
        else if (index === arr.length - 1) {
            return true;
        }
        // Otherwise, keep this item only if its y value is different from the next item's y value
        const nextItem = arr[index + 1];
        return c.y !== nextItem.y;
    });
}


export default function ProductPage() {

    const imgRef = useRef();

    const {productId} = useParams();
    const [product, setProduct] = useState(null);
    const [dataSets, setDataSets] = useState([]);
    const {Api} = useContext(ApiContext);

    const location = useLocation();

    useEffect(() => {
        Api.get({
            method: 'get_product.php',
            params: {
                uuid: productId
            }
        }).then(response => {
            const choppedData = chopRangeIntoDays(response.history);
            setProduct(response);
            const datasets = [{
                label: 'Cena',
                data: filterConsecutiveDuplicates(choppedData.map(c => ({x: c.x, y: c.y[0]}))),
                borderColor: '#60697b',
                backgroundColor: '#60697b',
            }];
            const lpd = filterConsecutiveDuplicates(choppedData.map(c => ({x: c.x, y: c.y[2]})));
            const dd = filterConsecutiveDuplicates(choppedData.map(c => ({x: c.x, y: c.y[1]})));
            if (lpd.length > 1 || (lpd.length === 1 && lpd[0].y !== null)) {
                datasets.push({
                    label: ({
                        barbora: 'Ar Paldies karti',
                        rimi: 'Ar Mans Rimi karti'
                    }[response.shop]),
                    data: lpd,
                    borderColor: '#36a2eb',
                    backgroundColor: '#36a2eb',
                });
            }
            if (dd.length > 1 || (dd.length === 1 && dd[0].y !== null)) {
                datasets.push({
                    label: 'Akcijas cena',
                    data: dd,
                    borderColor: '#45c4a0',
                    backgroundColor: '#45c4a0',
                });
            }
            setDataSets(datasets);
        });


    }, [location]);

    useEffect(() => {
        if (product) {
            if (imgRef.current) {
                const lightbox = GLightbox({
                    selector: '*[data-glightbox]',
                    touchNavigation: true,
                    loop: false,
                    zoomable: false,
                    autoplayVideos: true,
                    moreLength: 0,
                    slideExtraAttributes: {
                        poster: ''
                    },
                    plyr: {
                        css: '',
                        js: '',
                        config: {
                            ratio: '',
                            fullscreen: {
                                enabled: false,
                                iosNative: false
                            },
                            youtube: {
                                noCookie: true,
                                rel: 0,
                                showinfo: 0,
                                iv_load_policy: 3
                            },
                            vimeo: {
                                byline: false,
                                portrait: false,
                                title: false,
                                transparent: false
                            }
                        }
                    },
                });
            }
        }
    }, [product]);

    return (product ? <>

        <section className={`wrapper bg-light ` + styles.productPage}>
            <div className="container py-8 py-md-10">
                <div className="row gx-md-8 gx-xl-10 gy-8">
                    <div className="col-lg-5">
                        <div className="swiper-container" data-margin="10" data-dots="false" data-nav="false" data-thumbs="false">
                            <div className="swiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <figure className="rounded" style={{display: 'flex', position: 'relative'}}>
                                            <img style={{
                                                width: 'auto',
                                                margin: 'auto',
                                                maxHeight: '350px'
                                            }} src={Api.URL + product.picture} alt=""/><a className="item-link" href={Api.URL + product.picture} data-glightbox data-gallery="product-group" ref={imgRef}><i className="uil uil-focus-add"></i></a>
                                            <img src={`/imgs/shop-` + product.shop + `.png`} style={{
                                                height: '1.7rem',
                                                width: 'auto',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                            }} alt="" className={styles.shopLogo}/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="post-header mb-5">
                            <h2 className="post-title display-5">
                                <a href={'/produkts/' + productId} className="link-dark">{product.title}</a></h2>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div className={styles.priceWrapper} style={{
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start'
                                }}>
                                    <div className={styles.priceLine} style={{
                                        flexBasis: '100%',
                                        alignItems: 'center',
                                        fontSize: '1.4rem',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <div className={(product.price_2 ? styles.discount : 'd-flex gap-1 price')}>
                                            <span>€{product.price_1.toFixed(2)}</span>{product.loyalty_card_needed ?
                                            <img src={`/imgs/` + product.shop + `.png`} style={{height: '1em'}}/> : ''}
                                        </div>
                                        {product.price_2 &&
                                            <div className={styles.old}>
                                                <span>€{product.price_2.toFixed(2)}</span>
                                            </div>}
                                    </div>
                                    <div className={styles.base}>{product.base_price.toLowerCase()}</div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-9 d-flex flex-row pt-2">
                                <div className="flex-grow-1 mx-2">
                                    <a href={product.url} target={`_blank`} rel={`noreferrer`} className="btn btn-primary btn-icon btn-icon-start rounded w-100 flex-grow-1"><i className="uil uil-shopping-bag"></i> doties
                                        uz veikalu</a>
                                </div>
                                <div>
                                    <button className="btn btn-block btn-red btn-icon rounded px-3 w-100 h-100" disabled={true}>
                                        <i className="uil uil-heart"></i></button>
                                </div>
                            </div>
                        </div>

                        {product.related.length ? <>
                                <h2 className={`h2 text-body mb-2 mt-6`}>Citos veikalos</h2>
                                <ul style={{paddingLeft: 0}} className={styles.otherShops}>
                                    {product.related.map(p => <li key={p.uuid} style={{
                                        display: 'block',
                                        marginBottom: '0.5em'
                                    }}>
                                        <Link to={`/produkts/` + p.uuid}
                                              style={{
                                                  display: 'flex',
                                                  flexWrap: 'wrap',
                                                  alignItems: 'center',
                                                  border: '1px solid #60697b',
                                                  padding: '0 1em',
                                                  position: 'relative'
                                              }}
                                              className={`flex-md-nowrap`}
                                              replace={true}>
                                            <img src={`/imgs/shop-` + p.shop + `.png`} style={{
                                                height: '1em',
                                                position: 'absolute',
                                                top: '-0.5em',
                                                right: '-0.5em',
                                                background: 'rgba(var(--bs-light-rgb),var(--bs-bg-opacity))'
                                            }} alt="" className={styles.shopLogo}/>
                                            <div style={{lineHeight: 1}} className={`d-none d-md-block`}>
                                                <img src={Api.URL + p.picture} style={{width: '3em'}}/>
                                            </div>
                                            <div className={`m-0 mx-md-2 flex-grow-1 flex-md-grow-0 flex-shrink-0 flex-md-shrink-1`} style={{flexBasis: '100%'}}>{p.title}</div>
                                            <div style={{color: '#60697b'}} className={`ms-0 ms-md-auto`}>
                                                <div className={styles.priceWrapper} style={{
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'flex-end'
                                                }}>
                                                    <div className={styles.priceLine} style={{
                                                        flexBasis: '100%',
                                                        alignItems: 'center',
                                                        fontSize: '1.4rem',
                                                        justifyContent: 'flex-end'
                                                    }}>
                                                        <div className={(p.price_2 ? styles.discount : 'd-flex gap-1 price')}>
                                                            <span>€{p.price_1.toFixed(2)}</span>{p.loyalty_card_needed ?
                                                            <img src={`/imgs/` + p.shop + `.png`} style={{height: '1em'}}/> : ''}
                                                        </div>
                                                        {p.price_2 &&
                                                            <div className={styles.old}>
                                                                <span>€{p.price_2.toFixed(2)}</span>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>)}
                                </ul>
                            </>
                            : ''}
                    </div>
                </div>

                <ul className="nav nav-tabs nav-tabs-basic mt-12">
                    <li className="nav-item">
                        <a className="nav-link active" data-bs-toggle="tab" href="#price-history">Cenu vēsture</a>
                    </li>
                </ul>
                <div className="tab-content mt-0 mt-md-5">
                    <div className="tab-pane fade show active" id="price-history">
                        <div style={{width: '100%', height: '400px'}}>
                            <Line options={Xoptions} data={{datasets: dataSets}}/>
                        </div>
                    </div>
                </div>

            </div>
        </section>


        {/*<JSONTree data={product} hideRoot={true} />*/}

    </> : '');
}
