import React from 'react';
import { Pagination } from "react-bootstrap";

export default function Pager({ totalPages, onChangePage, page, className }) {

	const mergeClassNames = (defaultClassName, className) => {
		const classNamesArray = `${defaultClassName} ${className}`.split(' ');
		const uniqueClassNames = new Set(classNamesArray);
		return Array.from(uniqueClassNames).join(' ');
	};

	const handlePageChange = (pageNum) => {
		onChangePage(pageNum);
	};

	let pages = [];

	for (let i = 1; i <= totalPages; i++) {
		// Include 3 numbered links before and after current page
		if (i === 1 || i === totalPages || (i >= page - 2 && i <= page + 2)) {
			pages.push(
				<Pagination.Item
					className={(i < page-1 || i > page+1) ? 'd-none d-lg-inline-block' : ''}
					key={i}
					active={i === page}
					onClick={() => handlePageChange(i)}
				>
					{i}
				</Pagination.Item>
			);
		}
	}

	return (
		<div className={mergeClassNames(`d-flex justify-content-between`, className)}>
			<Pagination style={{ marginBottom: 0 }}>
				<Pagination.Prev
					disabled={page === 1}
					onClick={() => handlePageChange(page - 1)}
					className={`text-nowrap`}
				>
					<i className="uil uil-arrow-left"></i>
				</Pagination.Prev>
				{pages}
				<Pagination.Next
					disabled={page === totalPages}
					onClick={() => handlePageChange(page + 1)}
					className={`text-nowrap`}
				>
					<i className="uil uil-arrow-right"></i>
				</Pagination.Next>
			</Pagination>
		</div>
	);
}
