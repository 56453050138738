import {useContext, useEffect, useState} from "react";
import {ApiContext} from "../contexts/Api";
import {Person} from "react-bootstrap-icons";
import {Button} from "react-bootstrap";
import {UserContext} from "../contexts/User";
import {useNavigate} from "react-router-dom";

const _u = new URL(document.location.href);
let _v = 0;
let _z = 0;

export default function Header() {
    const [searchValue, setSearchValue] = useState('');
    const {Api} = useContext(ApiContext);
    const {User} = useContext(UserContext);

    const navigate = useNavigate();

    const [page, setPage] = useState(parseInt(_u.searchParams.get('p') || 1));

    const doSearch = () => {
        Api.doSearch(1);
    };

    useEffect(() => {
        if (!_z || _v) {
            doSearch();
            _z = 1;
        }
    }, [page]);


    const handleInputChange = (e) => {
        Api.updateSearchTerm(e.target.value);
    };

    const handleLogout = e => {
        Api.post({method: 'logout.php'}).then(response => {
            if (response.status === 'ok') {
                User.authenticated = false;
                User.scopes = [];
                navigate('/');
            }
        });
    }

    useEffect(() => {
        setSearchValue(Api.searchTerm);
    }, [Api.searchTerm]);


    return (<header className="wrapper bg-light position-sticky top-0" style={{zIndex: 20}}>
        <nav className="navbar navbar-expand-lg center-nav navbar-light navbar-bg-light">
            <div className="container flex-lg-row flex-nowrap align-items-center">
                <div className="navbar-brand">
                    <a href="/">
                        LOGO
                    </a>
                </div>
                <form action="#" method="post" id="search" name="search-form" className="my-1" target="_blank" style={{
                    minWidth: '50%',
                    maxWidth: '70%'
                }}>
                    <div>
                        <div className="mc-field-group input-group">
                            <input type="search" style={{
                                height: 'auto',
                                borderTopLeftRadius: '2em',
                                borderBottomLeftRadius: '2em',
                                borderColor: (!/\b\w{3,}\b/.test(Api.searchTerm) ? 'rgba(63,120,224,0.65)' : 'var(--bs-primary)')
                            }} value={Api.searchTerm} onKeyUp={e => e.key === 'Enter' && doSearch()} onChange={handleInputChange} name="q" className="required email form-control py-1" placeholder="Meklēt" id="search"/>
                            <button className="btn btn-primary" style={{
                                borderTopRightRadius: '2em',
                                borderBottomRightRadius: '2em',
                                fontSize: '1.5em'
                            }} disabled={!/\b\w{3,}\b/.test(Api.searchTerm)} onClick={doSearch} type="button">
                                <svg style={{
                                    width: '1.3em',
                                    fill: 'currentColor'
                                }} viewBox="0 0 24 24" aria-hidden="true">
                                    <path d="M10.25 3.75c-3.59 0-6.5 2.91-6.5 6.5s2.91 6.5 6.5 6.5c1.795 0 3.419-.726 4.596-1.904 1.178-1.177 1.904-2.801 1.904-4.596 0-3.59-2.91-6.5-6.5-6.5zm-8.5 6.5c0-4.694 3.806-8.5 8.5-8.5s8.5 3.806 8.5 8.5c0 1.986-.682 3.815-1.824 5.262l4.781 4.781-1.414 1.414-4.781-4.781c-1.447 1.142-3.276 1.824-5.262 1.824-4.694 0-8.5-3.806-8.5-8.5z"></path>
                                </svg>
                            </button>
                        </div>
                        <div id="mce-responses2" className="clear">
                            <div className="response" id="mce-error-response2" style={{display: 'none'}}></div>
                            <div className="response" id="mce-success-response2" style={{display: 'none'}}></div>
                        </div>
                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                            <input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabIndex="-1" value=""/>
                        </div>
                        <div className="clear"></div>
                    </div>
                </form>
                <div className="navbar-other d-flex">
                    <ul className="flex-row align-items-center ms-auto mb-0 ps-0">
                        {User.authenticated ?
                            <li className="nav-item btn-group">
                                <a className="nav-link dropdown-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><Person/></a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li className="nav-item">
                                        <Button variant={`link`} className="dropdown-item justify-content-start" onClick={handleLogout}>Iziet</Button>
                                    </li>
                                </ul>
                            </li> : ''
                        }
                    </ul>
                </div>
            </div>
        </nav>
    </header>);
}
