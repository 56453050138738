import {createContext, useState} from "react";

export const UserContext = createContext(null);

export const UserProvider = props => {
    const [authenticated, setAuthenticated] = useState(false);
    const [scopes, setScopes] = useState(false);

    const User = {

        set authenticated(state) {
            setAuthenticated(state);
        },
        get authenticated() {
            return authenticated;
        },
        set scopes(scopes) {
            setScopes(scopes);
        },
        get scopes() {
            return scopes;
        },

        isCapable: thing => authenticated && scopes.indexOf(thing) > -1,
    };

    return <UserContext.Provider value={{User}}>
        {props.children}
    </UserContext.Provider>;
};
