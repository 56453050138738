import {useContext} from "react";
import {StorageContext} from "../contexts/Storage";
import {ApiContext} from "../contexts/Api";
import {UserContext} from "../contexts/User";
import styles from "../custom.module.css";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {Link as LinkIcon} from "react-bootstrap-icons";

export default function WidgetsOverlay() {

    const {Storage} = useContext(StorageContext);
    const {Api} = useContext(ApiContext);
    const {User} = useContext(UserContext);

    const handleProductsLink = e => {
        e.preventDefault();
        if (User.isCapable('admin')) {
            console.log('Linking products: ', Storage.overlayProductsList.map(p => p.uuid));
            Api.post({method: 'link_products.php', data: {uuids: [...Storage.overlayProductsList.map(p => p.uuid)]}}).then(response => {
                if (response.status === 'ok') {
                    const clonedList = [...Storage.overlayProductsList];
                    clonedList.forEach(p => Storage.toggleOverlayListItemState(p));
                }
            });
        }
    };

    return (<>


        {User.isCapable('admin') ?
            <div style={{
                position: 'fixed',
                top: '75px',
                right: '2px',
                width: 'auto',
                height: 'auto',
                background: 'transparent',
                zIndex: 10
            }}>
                {Storage.overlayProductsList.map(p => <div key={p.uuid}>
                    <div className={styles.productItem} style={{zoom: 0.4}} title={p.name}>
                        <figure>
                            <div className={styles.imageWrapper}>

                                <Link to={`/produkts/` + p.uuid}><img src={Api.URL + p.picture} alt="" className={styles.productImage}/></Link>
                                <img src={`/imgs/shop-` + p.shop + `.png`} alt="" className={styles.shopLogo}/>
                            </div>
                            {User.isCapable('admin') ?
                                <a className={styles.itemHistory} href={``} data-bs-toggle="white-tooltip" title="Pievienot sarakstam" onClick={e => {
                                    e.preventDefault();
                                    Storage.toggleOverlayListItemState(p);
                                }} style={{
                                    top: '0.2rem',
                                    right: '0.5rem',
                                    zoom: 2
                                }}><i className={`uil uil-` + (Storage.inOverlayList(p) ? 'minus' : 'plus')}></i></a> : ''}
                        </figure>
                    </div>
                </div>)}
                {Storage.overlayProductsList.length ? <div>
                    <Button type={`button`} size={`sm`} onClick={handleProductsLink} disabled={Storage.overlayProductsList.length <=  1}><LinkIcon style={{zoom: 2, marginRight: 4}}/>Salinkot</Button>
                </div> : ''}
            </div> : ''
        }

    </>);
}
