import {ApiContext} from "../contexts/Api";
import {useContext} from "react";
import styles from '../custom.module.css';
import {Link} from "react-router-dom";
import {UserContext} from "../contexts/User";
import {StorageContext} from "../contexts/Storage";

export default function ProductsListItem(props) {
    const {Api} = useContext(ApiContext);
    const {User} = useContext(UserContext);
    const {Storage} = useContext(StorageContext);

    return (
        <div className={styles.productItem + ` col-md-6 col-xl-3`}>
            <figure style={{overflow: 'hidden'}}>
                <div className={styles.imageWrapper}>

                    <Link to={`/produkts/` + props.data.uuid}><img src={Api.URL + props.data.picture} alt="" className={styles.productImage}/></Link>
                    <img src={`/imgs/shop-` + props.data.shop + `.png`} alt="" className={styles.shopLogo}/>
                </div>
                <Link className={styles.itemHistory} to={`/produkts/` + props.data.uuid + `#price-history`} data-bs-toggle="white-tooltip" state={{scrollTo: '#price-history'}} title="Skatīt cenu vēsturi"><i className="uil uil-chart-line"></i></Link>
                {User.isCapable('admin') ?
                    <a className={styles.itemHistory} href={``} data-bs-toggle="white-tooltip" title="Pievienot sarakstam" onClick={e => {
                        e.preventDefault();
                        Storage.toggleOverlayListItemState(props.data);
                    }} style={{top: '3.7rem'}}><i className={`uil uil-` + (Storage.inOverlayList(props.data) ? 'minus' : 'plus')}></i></a> : ''}
                <a href={props.data.url} target={`_blank`} rel={`noreferrer`} className={styles.itemCart}><i className="uil uil-shopping-bag"></i>{` `}Uz
                    veikalu</a>
            </figure>
            <div className="post-header">
                <div className={styles.title}><Link to={`/produkts/` + props.data.uuid}>{props.data.name}</Link></div>
                <div className={styles.priceWrapper}>
                    <div className={styles.priceLine}>
                        <div className={(props.data.price_2 ? styles.discount : 'd-flex gap-1 price')}>
                            <span>€{props.data.price_1.toFixed(2)}</span>{props.data.loyalty_card_needed ?
                            <img src={`/imgs/` + props.data.shop + `.png`} style={{height: '1em'}}/> : ''}
                        </div>
                        {props.data.price_2 &&
                            <div className={styles.old}>
                                <span>€{props.data.price_2.toFixed(2)}</span>
                            </div>}
                    </div>
                    <div className={styles.base}>{props.data.base_price.toLowerCase()}</div>
                </div>
            </div>

        </div>
    );
}
