import {Button} from "react-bootstrap";
import {useContext} from "react";
import {ApiContext} from "../contexts/Api";

export default function Home() {

    const {Api} = useContext(ApiContext);

    const handleCustomSearch = text => {
        let i = 0;
        Api.updateSearchTerm('');
        let typing = setInterval(() => {
            if (i < text.length) {
                Api.updateSearchTerm(oldTerm => oldTerm+text[i]);
                i++;
            } else {
                clearInterval(typing);
                Api.updateSearchTerm(oldTerm => {Api.doSearch({page:1,term:oldTerm,noReplace:true}); return oldTerm});
            }
        }, 20);
    }

    return (<div style={{display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '3rem', marginBottom: '3rem', flexWrap: 'wrap'}}>
        <Button type={`button`} onClick={e => handleCustomSearch('Piens Baltais 1L')}>Piens Baltais 1L</Button>
        <Button type={`button`} onClick={e => handleCustomSearch('Sieriņš Kārums')}>Sieriņš Kārums</Button>
        <Button type={`button`} onClick={e => handleCustomSearch('Latvijas tomāti')}>Latvijas tomāti</Button>
        <Button type={`button`} onClick={e => handleCustomSearch('Skābais krējums')}>Skābais krējums</Button>
    </div>);
}
