import {Link, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {ApiContext} from "../contexts/Api";
import {UserContext} from "../contexts/User";

export default function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {Api} = useContext(ApiContext);
    const {User} = useContext(UserContext);

    const navigate = useNavigate();

    const handleLogin = e => {
        e.preventDefault();
        Api.post({method: 'login.php', data: {email, password}}).then(response => {
            if (response.status === 'ok') {
                User.authenticated = true;
                User.scopes = response.scopes;
                navigate('/');
            }
        });
    }

    const dummyTest = () => {
        User.test();
    };

    return (<section className="wrapper bg-light">
        <div className="container pb-14 pb-md-16">
            <div className="row">
                <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto mt-16">
                    <div className="card">
                        <div className="card-body p-11 text-center">
                            <h2 className="mb-3 text-start" onClick={dummyTest}>Lūgtum atpakaļ!</h2>
                            <p className="lead mb-6 text-start">Ievadi savu e-pasta adresi un paroli, lai turpinātu.</p>
                            <form className="text-start mb-3" onSubmit={handleLogin}>
                                <div className="form-floating mb-4">
                                    <input type="email" value={email} onChange={e => setEmail(e.currentTarget.value)} className="form-control" placeholder="E-pasta adrese" id="loginEmail"/>
                                    <label htmlFor="loginEmail">E-pasta adrese</label>
                                </div>
                                <div className="form-floating password-field mb-4">
                                    <input type="password" value={password} onChange={e => setPassword(e.currentTarget.value)} className="form-control" placeholder="Parole" id="loginPassword"/>
                                    <span className="password-toggle"><i className="uil uil-eye"></i></span>
                                    <label htmlFor="loginPassword">Parole</label>
                                </div>
                                <button type={`submit`} className="btn btn-primary rounded-pill btn-login w-100 mb-2">Pievienoties</button>
                            </form>
                            <p className="mb-1"><Link to={`/password-recovery/`} className="hover">Aizmirsi
                                paroli?</Link></p>
                            <p className="mb-0">Nav konta? <Link to={`/register/`} className="hover">Reģistrējies</Link>!
                            </p>
                            <div className="divider-icon my-4">vai</div>
                            <nav className="nav social justify-content-center text-center">
                                <a href="#" onClick={() => alert('Vēl ne...')} className="btn btn-circle btn-sm btn-google"><i className="uil uil-google"></i></a>
                                <a href="#" onClick={() => alert('Vēl ne...')} className="btn btn-circle btn-sm btn-facebook-f"><i className="uil uil-facebook-f"></i></a>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>);
}
