import {Route, Routes, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import ScrollToTop from "../components/ScrollToTop";
import SearchResults from "./SearchResults";
import PrivacyPolicy from "./PrivacyPolicy";
import UserAgreement from "./UserAgreement";
import About from "./About";
import ProductPage from "./ProductPage";
import LoginPage from "./LoginPage";
import Home from "./Home";

export default function ContentRoot() {
    let navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.shiftKey && event.key === 'L') {
                event.preventDefault();
                navigate("/login/");
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    });

    return (<>
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/meklesana/" element={<SearchResults/>}/>
            <Route path="/privatuma-politika/" element={<PrivacyPolicy/>}/>
            <Route path="/noteikumi/" element={<UserAgreement/>}/>
            <Route path="/par-mums/" element={<About/>}/>
            <Route path="/produkts/:productId" element={<ProductPage/>}/>
            <Route path="/login/" element={<LoginPage/>}/>
        </Routes>
    </>);
}
