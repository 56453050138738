import {createContext, useState} from "react";

export const StorageContext = createContext(null);
export default function StorageProvider(props) {
    const [overlayProductsList, setOverlayProductsList] = useState([]);


    const Storage = {

        get overlayProductsList() {
            return overlayProductsList;
        },
        inOverlayList: item => {
            return overlayProductsList.map(i => i.uuid).indexOf(item.uuid) > -1;
        },
        toggleOverlayListItemState: item => {
            setOverlayProductsList(_list => {
                const inList = _list.findIndex(i => i.uuid === item.uuid);
                if (inList === -1) {
                    return [..._list, item];
                } else {
                    const newList = [..._list];
                    newList.splice(inList, 1);
                    return newList;
                }
            });
        },
    };

    return (<StorageContext.Provider value={{Storage}}>
        {props.children}
    </StorageContext.Provider>);
}
